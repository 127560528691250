<template>
    <vx-card :title="title">
        <vs-tabs>
            <vs-tab label="Open">
                <New></New>
            </vs-tab>
            <vs-tab label="Active">
                <Active></Active>
            </vs-tab>
            <vs-tab label="Inactive">
                <Inactive></Inactive>
            </vs-tab>
            <vs-tab label="Canceled">
                <Canceled></Canceled>
            </vs-tab>
        </vs-tabs>
    </vx-card>
</template>
<script>
import New from "./_tab/tab-new.vue";
import Active from "./_tab/tab-active.vue";
import Inactive from "./_tab/tab-inactive.vue";
import Canceled from "./_tab/tab-canceled.vue";
export default {
    components: {
        New,
        Active,
        Inactive,
        Canceled,
    },
    data() {
        return {
            title: "Bill Of Material",
        };
    },
};
</script>
